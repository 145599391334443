//API server
// export const API_SERVER = 'http://localhost:3000/';
// ACTIONS project
export const STEP_CABINET_CHOOSE_PRODUCT = 'STEP_CABINET_CHOOSE_PRODUCT';
export const UPDATE_3D_CEIL_HEIGHT_UNIT = 'UPDATE_3D_CEIL_HEIGHT_UNIT';
export const UPDATE_CEIL_HEIGHT_UNIT = 'UPDATE_CEIL_HEIGHT_UNIT';
export const UPDATE_3D_CEIL_HEIGHT = 'UPDATE_3D_CEIL_HEIGHT';
export const UPDATE_CEIL_HEIGHT = 'UPDATE_CEIL_HEIGHT';
export const MOVE_COMPONENT = 'MOVE_COMPONENT';
export const STOP_DRAWING_LINE = 'STOP DRAWING LINE';
export const NEW_PROJECT = 'NEW_PROJECT';
export const LOAD_PROJECT = 'LOAD_PROJECT';
export const SAVE_PROJECT = 'SAVE_PROJECT';
export const OPEN_CATALOG = 'OPEN_CATALOG';
export const SELECT_TOOL_EDIT = 'SELECT_TOOL_EDIT';
export const UNSELECT_ALL = 'UNSELECT_ALL';
export const SELECT_ALL = 'SELECT_ALL';
export const SET_PROPERTIES = 'SET_PROPERTIES';
export const SET_ITEMS_ATTRIBUTES = 'SET_ITEMS_ATTRIBUTES';
export const SET_LINES_ATTRIBUTES = 'SET_LINES_ATTRIBUTES';
export const SET_HOLES_ATTRIBUTES = 'SET_HOLES_ATTRIBUTES';
export const REMOVE = 'REMOVE';
export const UNDO = 'UNDO';
export const REDO = 'REDO';
export const UNCREATE = 'UNCREATE';
export const RECREATE = 'RECREATE';
export const PROJECT_RE_NAME = 'PROJECT_RE_NAME';
export const SHIFT2DOFF = 'SHIFT2DOFF';
export const SHIFT2DON = 'SHIFT2DON';
export const ROLLBACK = 'ROLLBACK';
export const SET_PROJECT_PROPERTIES = 'SET_PROJECT_PROPERTIES';
export const SET_PROJECT_ID = 'SET_PROJECT_ID';
export const OPEN_PROJECT_CONFIGURATOR = 'OPEN_PROJECT_CONFIGURATOR';
export const INIT_CATALOG = 'INIT_CATALOG';
export const UPDATE_MOUSE_COORDS = 'UPDATE_MOUSE_COORDS';
export const UPDATE_ZOOM_SCALE = 'UPDATE_ZOOM_SCALE';
export const TOGGLE_SNAP = 'TOGGLE_SNAP';
export const CHANGE_CATALOG_PAGE = 'CHANGE_CATALOG_PAGE';
export const GO_BACK_TO_CATALOG_PAGE = 'GO_BACK_TO_CATALOG_PAGE';
export const THROW_ERROR = 'THROW_ERROR';
export const THROW_WARNING = 'THROW_WARNING';
export const COPY_PROPERTIES = 'COPY_PROPERTIES';
export const PASTE_PROPERTIES = 'PASTE_PROPERTIES';
export const PUSH_LAST_SELECTED_CATALOG_ELEMENT_TO_HISTORY = 'PUSH_LAST_SELECTED_CATALOG_ELEMENT_TO_HISTORY';
export const ALTERATE_STATE = 'ALTERATE_STATE';
export const SET_MODE = 'SET_MODE';
export const ADD_HORIZONTAL_GUIDE = 'ADD_HORIZONTAL_GUIDE';
export const ADD_VERTICAL_GUIDE = 'ADD_VERTICAL_GUIDE';
export const ADD_CIRCULAR_GUIDE = 'ADD_CIRCULAR_GUIDE';
export const REMOVE_HORIZONTAL_GUIDE = 'REMOVE_HORIZONTAL_GUIDE';
export const REMOVE_VERTICAL_GUIDE = 'REMOVE_VERTICAL_GUIDE';
export const REMOVE_CIRCULAR_GUIDE = 'REMOVE_CIRCULAR_GUIDE';
export const REMOVE_DRAWING_SUPPORT = 'REMOVE_DRAWING_SUPPORT';
export const SET_IS_HELP = 'SET_IS_HELP';
export const SET_IS_CABINET_DRAWING = "SET_IS_CABINET_DRAWING";

// ACTIONS viewer2D
export const SELECT_TOOL_ZOOM_IN = 'SELECT_TOOL_ZOOM_IN';
export const SELECT_TOOL_ZOOM_OUT = 'SELECT_TOOL_ZOOM_OUT';
export const SELECT_TOOL_PAN = 'SELECT_TOOL_PAN';
export const UPDATE_2D_CAMERA = 'UPDATE_2D_CAMERA';
export const CHANGE_WALL_LENGTH_MEASURE = 'CHANGE_WALL_LENGTH_MEASURE';
export const CHANGE_BASE_CABINET_MEASURE = 'CHANGE_BASE_CABINET_MEASURE';
export const CHANGE_WALL_CABINET_MEASURE = 'CHANGE_WALL_CABINET_MEASURE';
export const CHANGE_WINDOW_DOOR_MEASURE = 'CHANGE_WINDOW_DOOR_MEASURE';

//ACTIONS viewer3D
export const SELECT_TOOL_3D_VIEW = 'SELECT_TOOL_3D_VIEW';
export const SELECT_TOOL_3D_FIRST_PERSON = 'SELECT_TOOL_3D_FIRST_PERSON';

//ACTIONS items
export const VALIDATE_ITEM_POSTIONS = 'VALIDATE_ITEM_POSTIONS';
export const STORE_DIST_ARRAY = 'STORE_DIST_ARRAY';
export const END_LOADING = 'END_LOADING';
export const TOGGLE_LOADING_CABINET = 'TOGGLE_LOADING_CABINET'
export const EDIT_WIDTH = 'EDIT_WIDTH'
export const DUPLICATE_SELECTED = 'DUPLICATE_SELECTED';
export const REPLACE_ITEM = 'REPLACE_ITEM';
export const SELECT_TOOL_DRAWING_ITEM = 'SELECT_TOOL_DRAWING_ITEM';
export const SELECT_TOOL_DRAWING_ITEM_3D = 'SELECT_TOOL_DRAWING_ITEM_3D';
export const UPDATE_DRAWING_ITEM = 'UPDATE_DRAWING_ITEM';
export const END_DRAWING_ITEM = 'END_DRAWING_ITEM';
export const BEGIN_DRAGGING_ITEM = 'BEGIN_DRAGGING_ITEM';
export const BEGIN_DRAGGING_ITEM_3D = 'BEGIN_DRAGGING_ITEM_3D';
export const UPDATE_DRAGGING_ITEM = 'UPDATE_DRAGGING_ITEM';
export const UPDATE_DRAGGING_ITEM_CHANGED = 'UPDATE_DRAGGING_ITEM_CHANGED';
export const UPDATE_DRAGGING_ITEM_3DX = 'UPDATE_DRAGGING_ITEM_3DX';
export const UPDATE_DRAGGING_ITEM_3DY = 'UPDATE_DRAGGING_ITEM_3DY';
export const UPDATE_MOVING_STATE = 'UPDATE_MOVING_STATE';
export const END_DRAGGING_ITEM = 'END_DRAGGING_ITEM';
export const END_DRAGGING_ITEM_3D = 'END_DRAGGING_ITEM_3D';
export const BEGIN_ROTATING_ITEM = 'BEGIN_ROTATING_ITEM';
export const BEGIN_ROTATING_ITEM_3D = 'BEGIN_ROTATING_ITEM_3D';
export const UPDATE_ROTATING = 'UPDATE_ROTATING';
export const UPDATE_ROTATING_ITEM = 'UPDATE_ROTATING_ITEM';
export const UPDATE_ROTATING_ITEM_CHANGED = 'UPDATE_ROTATING_ITEM_CHANGED';
export const END_ROTATING_ITEM = 'END_ROTATING_ITEM';
export const END_ROTATING_ITEM_3D = 'END_ROTATING_ITEM_3D';
export const REPLACE_SUBMODULE = 'REPLACE_SUBMODULE';
export const ANIMATE_OBJECT = "ANIMATE_OBJECT";
export const REMOVE_REPLACE_SUBMODULE = "REMOVE_REPLACE_SUBMODULE";
export const ITEM_MOVE_UP = "ITEM_MOVE_UP";
export const SET_DOOR_STYLE = "SET_DOOR_STYLE";
export const SET_HANDLE_MATERIAL = 'SET_HANDLE_MATERIAL';
export const SET_INITIAL_DOOR_STYLE = "SET_INITIAL_DOOR_STYLE";
export const UPDATE_ITEM_POSITION = "UPDATE_ITEM_POSITION";
export const SET_DOOR_HANDLE = "SET_DOOR_HANDLE";
export const SET_WALL_COLOR = "SET_WALL_COLOR";
export const SET_MOLDING = "SET_MOLDING";
export const SET_LINEAR = "SET_LINEAR";
export const SET_FLOOR_STYLES = "SET_FLOOR_STYLES";
export const SET_COUNTER_TOP = "SET_COUNTER_TOP";
export const END_CREATING_CABINET = "END_CREATING_CABINET";
export const END_CREATING_HOLE = "END_CREATING_HOLE";
export const UPDATE_POPUP_OPEN = "UPDATE_POPUP_OPEN";
export const UPDATE_DRAGGING_HOLE_CHANGED = "UPDATE_DRAGGING_HOLE_CHANGED";
export const UPDATE_DRAGGING_HOLE_RULER_CHANGED = "UPDATE_DRAGGING_HOLE_RULER_CHANGED";
export const SET_MODELLING = "SET_MODELLING";
export const SET_BACKSPLASH = "SET_BACKSPLASH";
export const SET_BACKSPLASH_VISIBLE = "SET_BACKSPLASH_VISIBLE";
export const SET_APPLIANCE_MATERIAL = "SET_APPLIANCE_MATERIAL";
export const SET_MOVE_STATUS = "SET_MOVE_STATUS";
export const SET_ROTATE_STATUS = "SET_ROTATE_STATUS";
//ACTIONS groups
export const ADD_GROUP = 'ADD_GROUP';
export const ADD_GROUP_FROM_SELECTED = 'ADD_GROUP_FROM_SELECTED';
export const SELECT_GROUP = 'SELECT_GROUP';
export const UNSELECT_GROUP = 'UNSELECT_GROUP';
export const ADD_TO_GROUP = 'ADD_TO_GROUP';
export const REMOVE_FROM_GROUP = 'REMOVE_FROM_GROUP';
export const SET_GROUP_PROPERTIES = 'SET_GROUP_PROPERTIES';
export const SET_GROUP_ATTRIBUTES = 'SET_GROUP_ATTRIBUTES';
export const SET_GROUP_BARYCENTER = 'SET_GROUP_BARYCENTER';
export const REMOVE_GROUP = 'REMOVE_GROUP';
export const REMOVE_GROUP_AND_DELETE_ELEMENTS = 'REMOVE_GROUP_AND_DELETE_ELEMENTS';
export const GROUP_TRANSLATE = 'GROUP_TRANSLATE';
export const GROUP_ROTATE = 'GROUP_ROTATE';

//ACTION drawings
export const SELECT_HOLE = 'SELECT_HOLE';
export const SELECT_AREA = 'SELECT_AREA';
export const SELECT_ITEM = 'SELECT_ITEM';
export const SET_DISTANT = 'SET_DISTANT';
export const SELECT_LINE = 'SELECT_LINE';
export const SELECT_TOOL_DRAWING_LINE = 'SELECT_TOOL_DRAWING_LINE';
export const BEGIN_DRAWING_LINE = 'BEGIN_DRAWING_LINE';
export const UPDATE_DRAWING_LINE = 'UPDATE_DRAWING_LINE';
export const END_DRAWING_LINE = 'END_DRAWING_LINE';
export const SELECT_TOOL_DRAWING_HOLE = 'SELECT_TOOL_DRAWING_HOLE';
export const SELECT_TOOL_DRAWING_HOLE_3D = 'SELECT_TOOL_DRAWING_HOLE_3D';
export const UPDATE_DRAWING_HOLE = 'UPDATE_DRAWING_HOLE'; //SHOULD BE SLPITTED IN BEGIN_DRAWING_HOLE AND UPDATE_DRAWING_HOLE
export const UPDATE_DRAWING_HOLE_3D = 'UPDATE_DRAWING_HOLE_3D';
export const END_DRAWING_HOLE = 'END_DRAWING_HOLE';
export const END_DRAWING_HOLE_3D = 'END_DRAWING_HOLE_3D';
export const BEGIN_DRAGGING_LINE = 'BEGIN_DRAGGING_LINE';
export const UPDATE_DRAGGING_LINE = 'UPDATE_DRAGGING_LINE';
export const END_DRAGGING_LINE = 'END_DRAGGING_LINE';
export const SELECT_TOOL_UPLOAD_IMAGE = 'SELECT_TOOL_UPLOAD_IMAGE';
export const BEGIN_UPLOADING_IMAGE = 'BEGIN_UPLOADING_IMAGE';
export const END_UPLOADING_IMAGE = 'END_UPLOADING_IMAGE';
export const BEGIN_FITTING_IMAGE = 'BEGIN_FITTING_IMAGE';
export const END_FITTING_IMAGE = 'END_FITTING_IMAGE';
export const BEGIN_DRAGGING_HOLE = 'BEGIN_DRAGGING_HOLE';
export const BEGIN_DRAGGING_HOLE_3D = 'BEGIN_DRAGGING_HOLE_3D';
export const UPDATE_DRAGGING_HOLE = 'UPDATE_DRAGGING_HOLE';
export const END_DRAGGING_HOLE = 'END_DRAGGING_HOLE';
export const END_DRAGGING_HOLE_3D = 'END_DRAGGING_HOLE_3D';
export const UPDATE_DRAGGING_HOLE_3D = 'UPDATE_DRAGGING_HOLE_3D';
export const SET_RELATED_LINE = 'SET_RELATED_LINE';

//ACTIONS vertices
export const BEGIN_DRAGGING_VERTEX = 'BEGIN_DRAGGING_VERTEX';
export const UPDATE_DRAGGING_VERTEX = 'UPDATE_DRAGGING_VERTEX';
export const END_DRAGGING_VERTEX = 'END_DRAGGING_VERTEX';

//ACTIONS scene
export const SET_LAYER_PROPERTIES = 'SET_LAYER_PROPERTIES';
export const ADD_LAYER = 'ADD_LAYER';
export const SELECT_LAYER = 'SELECT_LAYER';
export const REMOVE_LAYER = 'REMOVE_LAYER';

//ACTIONS users
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';
export const SET_USER_DATA = 'SET_USER_DATA';

export const USER_ACTIONS = {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  SET_USER_DATA,
}
//ACTIONS state
export const SET_STATE_PROPERTIES = 'SET_STATE_PROPERTIES';
//GROUPING ACTIONS
export const PROJECT_ACTIONS = {
  NEW_PROJECT,
  LOAD_PROJECT,
  SAVE_PROJECT,
  OPEN_CATALOG,
  SELECT_TOOL_EDIT,
  UNSELECT_ALL,
  SELECT_ALL,
  SET_PROPERTIES,
  SET_ITEMS_ATTRIBUTES,
  SET_LINES_ATTRIBUTES,
  SET_HOLES_ATTRIBUTES,
  REMOVE,
  UNDO,
  REDO,
  UNCREATE,
  RECREATE,
  ROLLBACK,
  SET_PROJECT_PROPERTIES,
  SET_PROJECT_ID,
  PROJECT_RE_NAME,
  OPEN_PROJECT_CONFIGURATOR,
  INIT_CATALOG,
  UPDATE_MOUSE_COORDS,
  UPDATE_ZOOM_SCALE,
  TOGGLE_SNAP,
  CHANGE_CATALOG_PAGE,
  GO_BACK_TO_CATALOG_PAGE,
  THROW_ERROR,
  THROW_WARNING,
  COPY_PROPERTIES,
  PASTE_PROPERTIES,
  PUSH_LAST_SELECTED_CATALOG_ELEMENT_TO_HISTORY,
  ALTERATE_STATE,
  SET_MODE,
  ADD_HORIZONTAL_GUIDE,
  ADD_VERTICAL_GUIDE,
  ADD_CIRCULAR_GUIDE,
  REMOVE_HORIZONTAL_GUIDE,
  REMOVE_VERTICAL_GUIDE,
  REMOVE_CIRCULAR_GUIDE,
  REMOVE_DRAWING_SUPPORT,
  SET_STATE_PROPERTIES,
  SHIFT2DOFF,
  SHIFT2DON,
  SET_IS_HELP,
  SET_IS_CABINET_DRAWING
};

export const VIEWER2D_ACTIONS = {
  SELECT_TOOL_ZOOM_IN,
  SELECT_TOOL_ZOOM_OUT,
  SELECT_TOOL_PAN,
  UPDATE_2D_CAMERA,
  CHANGE_WALL_LENGTH_MEASURE,
  CHANGE_BASE_CABINET_MEASURE,
  CHANGE_WALL_CABINET_MEASURE,
  CHANGE_WINDOW_DOOR_MEASURE,
  UPDATE_CEIL_HEIGHT,
  UPDATE_CEIL_HEIGHT_UNIT
};

export const VIEWER3D_ACTIONS = {
  SELECT_TOOL_3D_VIEW,
  SELECT_TOOL_3D_FIRST_PERSON,
  UPDATE_3D_CEIL_HEIGHT,
  UPDATE_3D_CEIL_HEIGHT_UNIT
};

export const ITEMS_ACTIONS = {
  SELECT_ITEM,
  SELECT_TOOL_DRAWING_ITEM,
  SELECT_TOOL_DRAWING_ITEM_3D,
  UPDATE_DRAWING_ITEM,
  END_DRAWING_ITEM,
  BEGIN_DRAGGING_ITEM,
  BEGIN_DRAGGING_ITEM_3D,
  UPDATE_DRAGGING_ITEM,
  UPDATE_DRAGGING_ITEM_CHANGED,
  UPDATE_ROTATING_ITEM_CHANGED,
  UPDATE_DRAGGING_ITEM_3DX,
  UPDATE_DRAGGING_ITEM_3DY,
  END_DRAGGING_ITEM,
  END_DRAGGING_ITEM_3D,
  BEGIN_ROTATING_ITEM,
  BEGIN_ROTATING_ITEM_3D,
  UPDATE_ROTATING,
  UPDATE_ROTATING_ITEM,
  END_ROTATING_ITEM,
  END_ROTATING_ITEM_3D,
  REPLACE_SUBMODULE,
  ANIMATE_OBJECT,
  REMOVE_REPLACE_SUBMODULE,
  ITEM_MOVE_UP,
  SET_DOOR_STYLE,
  SET_HANDLE_MATERIAL,
  SET_INITIAL_DOOR_STYLE,
  UPDATE_ITEM_POSITION,
  SET_DOOR_HANDLE,
  SET_WALL_COLOR,
  SET_MOLDING,
  SET_LINEAR,
  END_CREATING_CABINET,
  UPDATE_POPUP_OPEN,
  STOP_DRAWING_LINE,
  SET_MODELLING,
  SET_COUNTER_TOP,
  SET_BACKSPLASH,
  SET_BACKSPLASH_VISIBLE,
  SET_APPLIANCE_MATERIAL,
  // SET_MOVE_STATUS,
  // SET_ROTATE_STATUS,
  DUPLICATE_SELECTED,
  EDIT_WIDTH,
  TOGGLE_LOADING_CABINET,
  END_LOADING,
  STORE_DIST_ARRAY,
  VALIDATE_ITEM_POSTIONS,
  REPLACE_ITEM
};

export const HOLE_ACTIONS = {
  SELECT_HOLE,
  SELECT_TOOL_DRAWING_HOLE,
  SELECT_TOOL_DRAWING_HOLE_3D,
  UPDATE_DRAWING_HOLE,
  UPDATE_DRAWING_HOLE_3D,
  END_DRAWING_HOLE,
  END_DRAWING_HOLE_3D,
  BEGIN_DRAGGING_HOLE,
  BEGIN_DRAGGING_HOLE_3D,
  UPDATE_DRAGGING_HOLE,
  UPDATE_DRAGGING_HOLE_3D,
  END_DRAGGING_HOLE,
  END_DRAGGING_HOLE_3D,
  UPDATE_DRAGGING_HOLE_CHANGED,
  UPDATE_DRAGGING_HOLE_RULER_CHANGED
};

export const LINE_ACTIONS = {
  SELECT_LINE,
  SELECT_TOOL_DRAWING_LINE,
  BEGIN_DRAWING_LINE,
  UPDATE_DRAWING_LINE,
  END_DRAWING_LINE,
  BEGIN_DRAGGING_LINE,
  UPDATE_DRAGGING_LINE,
  END_DRAGGING_LINE,
  SET_RELATED_LINE,
};

export const AREA_ACTIONS = {
  SELECT_AREA,
  SET_FLOOR_STYLES
};

export const GROUP_ACTIONS = {
  ADD_GROUP,
  ADD_GROUP_FROM_SELECTED,
  SELECT_GROUP,
  UNSELECT_GROUP,
  ADD_TO_GROUP,
  REMOVE_FROM_GROUP,
  SET_GROUP_PROPERTIES,
  SET_GROUP_ATTRIBUTES,
  SET_GROUP_BARYCENTER,
  REMOVE_GROUP,
  REMOVE_GROUP_AND_DELETE_ELEMENTS,
  GROUP_TRANSLATE,
  GROUP_ROTATE
};

export const SCENE_ACTIONS = {
  ADD_LAYER,
  SET_LAYER_PROPERTIES,
  SELECT_LAYER,
  REMOVE_LAYER,
  UPDATE_MOVING_STATE
};

export const VERTEX_ACTIONS = {
  BEGIN_DRAGGING_VERTEX,
  UPDATE_DRAGGING_VERTEX,
  END_DRAGGING_VERTEX
};

//MODES
export const MODE_IDLE = 'MODE_IDLE';
export const MODE_IDLE_3D = 'MODE_IDLE_3D';
export const MODE_2D_ZOOM_IN = 'MODE_2D_ZOOM_IN';
export const MODE_2D_ZOOM_OUT = 'MODE_2D_ZOOM_OUT';
export const MODE_2D_PAN = 'MODE_2D_PAN';
export const MODE_3D_VIEW = 'MODE_3D_VIEW';
export const MODE_3D_FIRST_PERSON = 'MODE_3D_FIRST_PERSON';
export const MODE_WAITING_DRAWING_LINE = 'MODE_WAITING_DRAWING_LINE';
export const MODE_DRAGGING_LINE = 'MODE_DRAGGING_LINE';
export const MODE_DRAGGING_VERTEX = 'MODE_DRAGGING_VERTEX';
export const MODE_DRAGGING_ITEM = 'MODE_DRAGGING_ITEM';
export const MODE_DRAGGING_ITEM_3D = 'MODE_DRAGGING_ITEM_3D';
export const MODE_DRAGGING_HOLE = 'MODE_DRAGGING_HOLE';
export const MODE_DRAGGING_HOLE_3D = 'MODE_DRAGGING_HOLE_3D';
export const MODE_DRAWING_LINE = 'MODE_DRAWING_LINE';
export const MODE_DRAWING_HOLE = 'MODE_DRAWING_HOLE';
export const MODE_DRAWING_HOLE_3D = 'MODE_DRAWING_HOLE_3D';
export const MODE_DRAWING_ITEM = 'MODE_DRAWING_ITEM';
export const MODE_DRAWING_ITEM_3D = 'MODE_DRAWING_ITEM_3D';
export const MODE_ROTATING_ITEM = 'MODE_ROTATING_ITEM';
export const MODE_ROTATING_ITEM_3D = 'MODE_ROTATING_ITEM_3D';
export const MODE_UPLOADING_IMAGE = 'MODE_UPLOADING_IMAGE';
export const MODE_FITTING_IMAGE = 'MODE_FITTING_IMAGE';
export const MODE_VIEWING_CATALOG = 'MODE_VIEWING_CATALOG';
export const MODE_CONFIGURING_PROJECT = 'MODE_CONFIGURING_PROJECT';

export const ARRAY_3D_MODES = [MODE_ROTATING_ITEM_3D, MODE_DRAGGING_ITEM_3D, MODE_IDLE_3D, MODE_DRAWING_ITEM_3D, MODE_DRAWING_HOLE_3D, MODE_DRAGGING_HOLE_3D];
//Thinking about it...
//https://developer.mozilla.org/it/docs/Web/JavaScript/Reference/Global_Objects/Proxy
/*
let MODE_DEF = [
  'IDLE',
  '2D_ZOOM_IN',
  '2D_ZOOM_OUT',
  '2D_PAN',
  '3D_VIEW',
  '3D_FIRST_PERSON',
  'WAITING_DRAWING_LINE',
  'DRAGGING_LINE',
  'DRAGGING_VERTEX',
  'DRAGGING_ITEM',
  'DRAGGING_HOLE',
  'DRAWING_LINE',
  'DRAWING_HOLE',
  'DRAWING_ITEM',
  'ROTATING_ITEM',
  'UPLOADING_IMAGE',
  'FITTING_IMAGE',
  'VIEWING_CATALOG',
  'CONFIGURING_PROJECT',
];

export const MODE = new Proxy( MODE_DEF, { get: (target, name) => { return target.indexOf(name) !== -1 ? name : null } } );
*/

export const MODE_SNAPPING = [
  MODE_IDLE, MODE_2D_ZOOM_IN, MODE_2D_ZOOM_OUT, MODE_2D_PAN,
  MODE_WAITING_DRAWING_LINE, MODE_DRAWING_LINE, MODE_DRAWING_HOLE,
  MODE_DRAWING_ITEM, MODE_DRAGGING_LINE, MODE_DRAGGING_VERTEX,
  MODE_DRAGGING_ITEM, MODE_DRAGGING_HOLE, MODE_DRAGGING_HOLE_3D, MODE_FITTING_IMAGE,
  MODE_UPLOADING_IMAGE, MODE_ROTATING_ITEM, MODE_DRAGGING_ITEM_3D
];

//UNITS
export const UNIT_MILLIMETER = 'mm';
export const UNIT_CENTIMETER = 'cm';
export const UNIT_METER = 'm';
export const UNIT_INCH = 'in';
export const UNIT_FOOT = 'ft';
export const UNIT_MILE = 'mi';

export const UNITS_LENGTH = [
  UNIT_MILLIMETER,
  UNIT_CENTIMETER,
  UNIT_METER,
  UNIT_INCH,
  UNIT_FOOT,
  UNIT_MILE
];

export const CEIL_UNITS_LENGTH = [
  UNIT_CENTIMETER,
  UNIT_METER,
  UNIT_INCH,
  UNIT_FOOT
]

export const EPSILON = 1e-2;

export const MEPSILON = 1;

export const KEYBOARD_BUTTON_CODE = {
  DELETE: 46,
  BACKSPACE: 8,
  ESC: 27,
  Z: 90,
  Q: 81,
  ALT: 18,
  C: 67,
  V: 86,
  CTRL: 17,
  ENTER: 13,
  TAB: 9,
  SHIFT:16
};
export const MODE = process.env.MODE;
export const API_SERVER_URL  = process.env.API_URL;
export const NO_DATA_DATABASE  = 'No cabinet data in database';
export const ERROR_DATABASE  = 'You should check up the database because of wrong data in database. ';

//toolbar
export const MAKE_FLOOR_PLAN  = 'Make Floor Plan';
export const SELECT_DOOR_STYLE  = 'Select Door Style';
export const ADD_CABINETS  = 'Add Cabinets';
export const ADD_APPLIANCES  = 'Add Appliances';
export const FINISHING_TOUCH  = 'Finishing Touch';
export const REVIEW_AND_QUOTE  = 'Review & Quote';
//control
export const SELECTALL  = 'Select All';
export const EDIT  = 'Edit';
export const TAKE_PICTURE  = 'Take Picture';
export const NEW_PROJECT_BTN = 'New Project';
//header
export const KITCHEN_KONFIGURATOR  = 'KITCHEN CONFIGURATOR';
export const REQUEST_ASSISTANCE  = 'Request Assistance';
export const SAVE_DESIGN = 'Save';
export const NEW_DESIGN = 'New';
export const SUBMIT_DESIGN = 'Designer Review';

export const OBJTYPE_MESH = 'Mesh';
export const OBJTYPE_GROUP = 'Group';

export const ARROW_COLOR = '#1183b7';
export const ARROW_TEXT_BACKCOLOR = "#fff";
export const ARROW_TEXT_FORECOLOR = "#3A0B80";
export const ARROW_TEXT_FONTFACE = "Milliard Book";

export const SHAPE_SVG_PADDING = 10; // in inch
export const SHAPE_SVG_WIDTH = 12; // in inch
export const SHAPE_SVG_DEPTH = 24; // in inch

export const HAS_LOADINGBAR = true;

export const LINE_THICKNESS = 18;

export let PROJECT_NAME_LENGTH_LIMIT = 25;

export const ZOOM_VARIABLE = 0.0049;

export const TEXT_COLOR_NEUTRAL_0 = '#000F33';
export const TEXT_COLOR_NEUTRAL_1 = '#434D63';
export const TEXT_COLOR_NEUTRAL_2 = '#565F77';
export const TEXT_COLOR_NEUTRAL_3 = '#8791AB';
export const TEXT_COLOR_NEUTRAL_4 = '#C1CAE4';
export const TEXT_COLOR_NEUTRAL_5 = '#EAEDFF';
export const STATUS_POSITIVE_COLOR = '#008758';
export const STATUS_POSITIVE_LIGHT_COLOR = '#AAE8D2';
export const STATUS_NEGATIVE_COLOR = '#FF0000';
export const STATUS_NEGATIVE_LIGHT_COLOR = '#FED1D4';
export const STATUS_WARNING_COLOR = '#FF7400';
export const STATUS_WARNING_LIGHT_COLOR = '#FFDEC5';
export const STATUS_INFO_COLOR = '#0047FF';
export const STATUS_INFO_LIGHT_COLOR = '#CFDCFF';
export const STATUS_OVERDUE_COLOR = '#C11041';
export const STATUS_OVERDUE_LIGHT_COLOR = '#FFEEEE';
export const PRIMARY_GREEN_COLOR = '#00B9B9';
export const SECONDARY_BLUE_COLOR = '#154AC5';
export const SECONDARY_PURPLE_COLOR = '#4C12A1';
export const SHADE_LIGHT_GREEN_COLOR = '#4DCBCB';
export const SHADE_DARK_GREEN_COLOR = '#008D8D';
export const SHADE_LIGHT_BLUE_COLOR = '#3A66CA';
export const SHADE_DARK_BLUE_COLOR = '#0C389D';
export const SHADE_LIGHT_PURPLE_COLOR = '#6332AC';
export const SHADE_DARK_PURPLE_COLOR = '#3A0B80';
export const BG_COLOR_0 = '#EAEDFF';
export const BG_COLOR_1 = '#FFFFFF';
export const BG_COLOR_HOVER = '#D9E8FF';
export const BG_COLOR_OVERLAY = '#565F77';
export const TITLE_COLOR = '#1A051D';
export const TITLE_SMALL_COLOR = '#3F3356';
export const LABEL_COLOR = '#6E7191';
export const TEXT_COLOR = '#000000';
export const ACCENT_COLOR = '#AD00FF';
export const BROWN_COLOR = '#624100';

export const DEFAULT_FONT_FAMILY = 'Open Sans';